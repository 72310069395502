* {
  font-family: 'Nunito', sans-serif;
}

@import url('https://fonts.googleapis.com/css2?family=Arimo:wght@400;500;600;700&family=Nunito:wght@200;300;400;500;600&display=swap');

.status{
  text-align: center;
  color: rgb(230, 193, 255);
}

@keyframes shake {

  10%,
  90% {
    transform: translate3d(-1px, 0, 0);
  }

  20%,
  80% {
    transform: translate3d(2px, 0, 0);
  }

  30%,
  50%,
  70% {
    transform: translate3d(-4px, 0, 0);
  }

  40%,
  60% {
    transform: translate3d(4px, 0, 0);
  }
}

@keyframes dots {

  0%,
  20% {
    color: rgba(0, 0, 0, 0);
    text-shadow:
      .25em 0 0 rgba(0, 0, 0, 0),
      .5em 0 0 rgba(0, 0, 0, 0);
  }

  40% {
    color: rgb(151, 151, 151);
    text-shadow:
      .25em 0 0 rgba(0, 0, 0, 0),
      .5em 0 0 rgba(0, 0, 0, 0);
  }

  60% {
    text-shadow:
      .25em 0 0 rgb(124, 124, 124),
      .5em 0 0 rgba(0, 0, 0, 0);
  }

  80%,
  100% {
    text-shadow:
      .25em 0 0 rgb(121, 121, 121),
      .5em 0 0 rgb(121, 121, 121);
  }
}

.btnfos-0-2:hover {
  border-color: #00acee;
}

@media screen and (max-width: 499px) {

  .icons {
    display: flex;
    margin-bottom: auto;
    margin-top: auto;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
  }

  .nftPicDiv {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
    margin-bottom: 5%;
  }

  .socialIcon img {
    width: 24px;
    height: 24px;
    cursor: pointer;
  }

  .price {
    text-align: center;
    font-size: 20px;
    color: #ffffff;
  }

  .allWrap {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    width: 100%;
    height: 100vh;
    min-height: 100vh;
    height: max-content;
  }

  .storyPicDiv {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
  }

  .intro {
    color: #ffffff;
    font-size: 40px;
    font-family: 'Arimo', sans-serif;
    color: white;
    line-height: 1.2;
    margin-top: 6%;
    margin-bottom: 6%;
    font-weight: bold;
    text-shadow: #0a0a0a96 3px 5px 2px;

  }

  .intro2 {
    color: rgba(255, 255, 255, 0.918);
    font-size: 20px;
    text-align: center;
    font-size: 20px;
    text-align: center;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    text-shadow: 0 0 10px #ffffffe1;
  }

  .mintDiv {

    width: 95%;
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 5px solid #00acee;
    margin-left: auto;
    margin-right: auto;
    border-radius: 20px;
    padding: 10px;
    margin-bottom: 5%;
    margin-top: 5%;
  }

  .errorMessage {
    font-size: 15px;
    color: #FF4742;
    text-align: center;
  }

  .loadingText {
    font-size: 20px;
    text-align: center;
    color: rgb(255, 255, 255);
  }

  .loadTextSub {
    text-align: center;
    color: rgb(255, 255, 255);
    font-size: 13px;
    padding-top: 5px;
  }

  /* loading dots */


  .loadingText:after {
    content: '.';
    animation: dots 1s steps(5, end) infinite;
  }


  .right {
    flex-flow: row nowrap;
    display: flex;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    width: max-content;
  }


  .socialIcon {
    padding-right: 5px;
    padding-left: 5px;
    transition: transform .2s;
    margin-top: auto;
    margin-bottom: auto;
  }

  .socialIcon:hover {
    transform: scale(1.1);
  }


  /* width */
  ::-webkit-scrollbar {
    width: 10px;
    display: none;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: #3b3b3b;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #888;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: rgb(99, 31, 177);
  }

  .connect {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    font-family: 'Nunito', sans-serif;
    padding-top: 5px;
    margin-left: 10%;
  }

  .connect2 {
    padding-top: 5px;
  }

  .connect div {
    margin-left: 10px;
    margin-right: 10px;
    font-family: 'Nunito', sans-serif;
    color: white;
    padding-top: 15px;
    font-size: 16px;
  }

  .connect div:hover {
    margin-left: 10px;
    margin-right: 10px;
    font-family: 'Nunito', sans-serif;
    color: rgb(184, 184, 184);
    padding-top: 15px;
    cursor: pointer;
  }

  .nftblockWalletConnectedALL {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
  }

  .minting_count_button {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    height: max-content;
  }

  .nftminter2 {
    color: #00acee;
    font-size: 65px;
  }

  .center {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    justify-content: center;
  }

  .nftminter {
    background-color: #18181a;
    justify-content: flex-start;
  }

  .mintbuttondiv {
    text-align: center;
  }

  .nftblockWalletConnected {
    display: flex;
    margin-bottom: 10px;
  }

  .btnfos-0-2 {
    background-color: rgba(255, 255, 255, 0);
    border: white 2px solid;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    font-size: 20px;
    margin-left: 20px;
    margin-right: 20px;
    width: 45px;
    height: 45px;
    border: none;
    cursor: pointer;
    border-radius: 6px;
    background-color: rgba(255, 255, 255, 0);
    border: 2px solid white;
    color: white;
    background-color: rgba(255, 255, 255, 0);
  }

  .btnfos-0-2:hover:active {
    background-color: #d3d3d3;
  }

  .btnfos-0-3 {
    font-size: 30px;
    margin-right: auto;
    margin-left: auto;
    justify-content: center;
    padding: 5px;
    border: none;
    justify-content: center;
    text-align: center;
    width: 250px;
    margin-bottom: 5%;
    color: white;
    background-color: rgba(255, 255, 255, 0);
    border: white 2px solid;
    margin-top: 5%;
    border-radius: 6px;
  }

  .btnfos-0-3:hover {
    color: #00acee;
    cursor: pointer;
  }

  .btnfos-0-3:hover:active {
    background-color: #d3d3d3;
  }

  .walletConnect {
    display: flex;
    margin-top: 2%;
    margin-bottom: 5%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  }

  .connectButton {
    font-size: 45px;
  }

  .loadingContainer {
    text-align: center;
  }

  .successfully {
    text-align: center;
    color: rgb(124, 199, 74);
    font-size: 25px;
  }

  .headers {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: space-between;
    padding-top: 20px;
    margin-left: auto;
    margin-right: auto;
  }

  .headers2 {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    width: 95%;
    margin-left: auto;
    margin-right: auto;
  }

  .introduction {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    margin-top: 2%;
    margin-left: auto;
    margin-right: auto;
    width: 90%;
  }

  .wallet2 {
    background-color: #9e4c3c00;
    border: #00acee 2px solid;
    color: white;
    padding: 5px;
    text-align: center;
    text-decoration: none;
    display: flex;
    font-size: 15px;
    padding-left: 15px;
    padding-right: 15px;
    transition: transform .2s;
    border-radius: 50px;
    font-weight: 500;
    margin-left: auto;
    margin-right: auto;
    margin-left: 5px;
    display: none;
  }

  .wallet2Mobile {
    background-color: #9e4c3c00;
    border: #00acee 2px solid;
    color: white;
    padding: 5px;
    text-align: center;
    text-decoration: none;
    display: flex;
    font-size: 15px;
    padding-left: 15px;
    padding-right: 15px;
    transition: transform .2s;
    border-radius: 50px;
    font-weight: 500;
    margin-left: auto;
    margin-right: auto;
    margin-left: 5px;
  }

  .wallet2Mobile:hover {
    transform: scale(1.1);
  }

  .wallet3 {
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 27px;
    padding-left: 50px;
    padding-right: 50px;
    align-items: center;
    display: flex;
    transition: transform .5s;
    font-weight: 500;
    color: white;
    background: #00acee;
    border: none;
    border-radius: 100px;
    box-shadow: rgba(0, 0, 0, 0.1) 1px 2px 4px;
    margin-top: 20px;
    margin-bottom: 20px;
    display: none;
  }

  .wallet3Mobile {
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 27px;
    padding-left: 50px;
    padding-right: 50px;
    align-items: center;
    display: flex;
    transition: transform .5s;
    font-weight: 500;
    color: white;
    background: #00acee;
    border: none;
    border-radius: 100px;
    box-shadow: rgba(0, 0, 0, 0.1) 1px 2px 4px;
    margin-top: 20px;
    margin-bottom: 20px;
  }

  .wallet3Mobile:hover {
    animation: shake 0.82s cubic-bezier(.36, .07, .19, .97) both;
    transform: translate3d(0, 0, 0);
    perspective: 1000px;
  }


  .in2 {
    width: 100%;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
  }

  .totalSupply {
    text-align: center;
    font-size: 35px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    color: white;
    text-shadow: 4px 3px 0 #383d6e3a;
  }

  .logoPic {
    width: 80px;
    height: 80px;
    cursor: pointer;
    border-radius: 100%;
    border: 3px solid #00acee;

  }

  .logo {
    margin-top: 15px;
    margin-bottom: 15px;
  }

  .light {
    background: linear-gradient(180deg, rgba(0, 0, 0, 0.5794489670868348) 0%, rgba(0, 0, 0, 0.8371520483193278) 49%, rgba(0, 0, 0, 0.9239867822128851) 100%), url('./assets/bg.jpg');
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    min-height: 100vh;
    height: max-content;
    overflow: hidden;
  }

  .nftPic {
    width: 90%;
    box-shadow: -8px 8px rgb(63, 63, 63);
     margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    border-radius: 20px;
  }

}

@media screen and (min-width: 500px) and (max-width: 767px) {

  .wallet2Mobile {
    display: none;
  }

  .wallet3Mobile {
    display: none;
  }

  .icons {
    display: flex;
    margin-bottom: auto;
    margin-top: auto;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
  }

  .nftPicDiv {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
    margin-bottom: 5%;
  }

  .mintingAmount {
    font-size: 20px;
    padding: 10px;
  }

  .socialIcon img {
    width: 25px;
    height: 25px;
    cursor: pointer;
  }

  .price {
    text-align: center;
    font-size: 25px;
    color: #ffffff;
  }

  .allWrap {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    width: 100%;
    height: 100vh;
    min-height: 100vh;
    height: max-content;
  }

  .storyPicDiv {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
  }

  .intro {
    color: #ffffff;
    font-size: 45px;
    margin-top: 6%;
    margin-bottom: 6%;
    color: white;
    line-height: 1.2;
    margin-left: auto;
    margin-left: auto;
    justify-content: center;
    text-align: center;
    font-family: 'Arimo', sans-serif;
    font-weight: bold;
    text-shadow: #0a0a0a96 3px 5px 2px;

  }

  .conH {
    color: #ffffff;
    font-size: 40px;
    letter-spacing: 3px;
    color: white;
    line-height: 1.2;
    text-align: center;
    margin-bottom: 2%;
    margin-top: 2%;
  }

  .intro2 {
    color: rgba(255, 255, 255, 0.918);
    font-size: 20px;
    text-align: center;
    font-size: 20px;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    text-shadow: 0 0 10px #ffffffe1;
  }

  .mintDiv {
    width: 90%;
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 5px solid #00acee;
    margin-left: auto;
    margin-right: auto;
    border-radius: 20px;
    padding: 10px;
    margin-bottom: 5%;
    margin-top: 5%;
  }

  .errorMessage {
    font-size: 16px;
    color: #FF4742;
    text-align: center;
  }

  .loadingText {
    font-size: 20px;
    text-align: center;
    color: rgb(255, 255, 255);
  }

  .loadTextSub {
    text-align: center;
    color: rgb(255, 255, 255);
    font-size: 13px;
    padding-top: 5px;
  }

  /* loading dots */


  .loadingText:after {
    content: '.';
    animation: dots 1s steps(5, end) infinite;

  }



  .wpPic {
    width: 500px;
    height: 500px;
    margin-left: 10%;

  }

  .right {
    flex-flow: row nowrap;
    display: flex;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    width: max-content;
  }

  .socialIcon {
    padding-right: 8px;
    padding-left: 8px;
    transition: transform .2s;
    margin-top: auto;
    margin-bottom: auto;
  }

  .socialIcon:hover {
    transform: scale(1.1);
  }


  /* width */
  ::-webkit-scrollbar {
    width: 10px;
    display: none;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: #3b3b3b;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #888;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: rgb(99, 31, 177);
  }

  .connect {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    font-family: 'Nunito', sans-serif;
    padding-top: 5px;
    margin-left: 10%;
  }

  .connect2 {
    padding-top: 5px;
  }

  .connect div {
    margin-left: 10px;
    margin-right: 10px;
    font-family: 'Nunito', sans-serif;
    color: white;
    padding-top: 15px;
    font-size: 16px;
  }

  .connect div:hover {
    margin-left: 10px;
    margin-right: 10px;
    font-family: 'Nunito', sans-serif;
    color: rgb(184, 184, 184);
    padding-top: 15px;
    cursor: pointer;
  }

  .nftblockWalletConnectedALL {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
  }

  .minting_count_button {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    height: max-content;
  }

  .nftminter2 {
    color: #00acee;
    font-size: 90px;
  }

  .center {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    justify-content: center;
  }

  .nftminter {
    background-color: #18181a;
    justify-content: flex-start;
  }

  .mintbuttondiv {
    text-align: center;
  }

  .nftblockWalletConnected {
    display: flex;
    margin-bottom: 10px;
  }

  .btnfos-0-2 {
    color: white;
    background-color: rgba(255, 255, 255, 0);
    border: white 2px solid;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    font-size: 20px;
    margin-left: 20px;
    margin-right: 20px;
    height: 50px;
    width: 50px;
    border: none;
    cursor: pointer;
    border-radius: 6px;
    background-color: rgba(255, 255, 255, 0);
    border: 2px solid white;
  }

  .btnfos-0-2:hover:active {
    background-color: #d3d3d3;
  }

  .btnfos-0-3 {
    font-size: 30px;
    margin-right: auto;
    margin-left: auto;
    justify-content: center;
    padding: 5px;
    border: none;
    justify-content: center;
    text-align: center;
    width: 250px;
    margin-bottom: 5%;
    color: white;
    background-color: rgba(255, 255, 255, 0);
    border: white 2px solid;
    margin-top: 5%;
    border-radius: 6px;
  }



  .btnfos-0-3:hover {
    color: #00acee;
    cursor: pointer;
  }

  .btnfos-0-3:hover:active {
    background-color: #d3d3d3;
  }

  .walletConnect {
    display: flex;
    margin-top: 2%;
    margin-bottom: 5%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  }

  .connectButton {
    font-size: 45px;
  }

  .loadingContainer {
    text-align: center;
  }

  .successfully {
    text-align: center;
    color: rgb(124, 199, 74);
    font-size: 25px;
  }

  .headers {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: space-between;
    padding-top: 30px;
    margin-left: auto;
    margin-right: auto;
  }

  .headers2 {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
  }

  .introduction {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    margin-top: 2%;
    margin-left: auto;
    margin-right: auto;
    width: 90%;
  }

  .wallet2 {
    background-color: #9e4c3c00;
    border: #00acee 2px solid;
    color: white;
    padding: 5px;
    text-align: center;
    text-decoration: none;
    display: flex;
    font-size: 16px;
    padding-left: 25px;
    padding-right: 25px;
    transition: transform .2s;
    border-radius: 50px;
    font-weight: 500;
    margin-left: 10px;
  }

  .wallet2:hover {
    transform: scale(1.1);
  }

  .wallet3 {
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 27px;
    padding-left: 50px;
    padding-right: 50px;
    align-items: center;
    display: flex;
    transition: transform .5s;
    font-weight: 500;
    color: white;
    background: #00acee;
    border: none;
    border-radius: 100px;
    box-shadow: rgba(0, 0, 0, 0.1) 1px 2px 4px;
    margin-bottom: 10%;
    margin-top: 20px;
  }

  .wallet3:hover {
    animation: shake 0.82s cubic-bezier(.36, .07, .19, .97) both;
    transform: translate3d(0, 0, 0);
    perspective: 1000px;
  }



  .in2 {
    width: 100%;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
  }

  .totalSupply {
    text-align: center;
    font-size: 45px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    color: white;
    text-shadow: 4px 3px 0 #383d6e3a;
  }

  .logoPic {
    width: 90px;
    height: 90px;
    cursor: pointer;
    border-radius: 100%;
    border: 3px solid #00acee;

  }


  .light {
    background: linear-gradient(180deg, rgba(0, 0, 0, 0.5794489670868348) 0%, rgba(0, 0, 0, 0.8371520483193278) 49%, rgba(0, 0, 0, 0.9239867822128851) 100%), url('./assets/bg.jpg');
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    min-height: 100vh;
    height: max-content;
    overflow: hidden;
  }

  .nftPic {
    width: 90%;
    box-shadow: -8px 8px rgb(63, 63, 63);
     margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    border-radius: 20px;
  }

}

@media screen and (min-width: 768px) and (max-width: 991px) {
  .wallet2Mobile {
    display: none;
  }

  .wallet3Mobile {
    display: none;
  }

  .icons {
    display: flex;
    margin-bottom: auto;
    margin-top: auto;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
  }

  .nftPicDiv {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
    margin-bottom: 5%;
  }

  .mintingAmount {
    font-size: 20px;
    padding: 10px;
  }

  .socialIcon img {
    width: 32px;
    height: 32px;
    cursor: pointer;
  }

  .price {
    text-align: center;
    font-size: 25px;
    color: #ffffff;
  }

  .allWrap {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    width: 100%;
    height: 100vh;
    min-height: 100vh;
    height: max-content;
  }

  .intro {
    color: #ffffff;
    font-size: 50px;
    margin-top: 6%;
    margin-bottom: 6%;
    font-family: 'Arimo', sans-serif;
    color: white;
    line-height: 1.2;
    font-weight: bold;
    text-shadow: #0a0a0a96 3px 5px 2px;

  }

  .intro2 {
    color: rgba(255, 255, 255, 0.918);
    font-size: 20px;
    font-size: 20px;
    text-align: center;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    text-shadow: 0 0 10px #ffffffe1;
  }

  .mintDiv {
    width: 60%;
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 5px solid #00acee;
    margin-left: auto;
    margin-right: auto;
    border-radius: 20px;
    padding: 10px;
    margin-bottom: 5%;
    margin-top: 5%;
  }

  .errorMessage {
    font-size: 18px;
    color: #FF4742;
    text-align: center;
  }

  .loadingText {
    font-size: 20px;
    text-align: center;
    color: rgb(255, 255, 255);
  }

  .loadTextSub {
    text-align: center;
    color: rgb(255, 255, 255);
    font-size: 13px;
    padding-top: 5px;
  }

  /* loading dots */


  .loadingText:after {
    content: '.';
    animation: dots 1s steps(5, end) infinite;
  }



  .right {
    flex-flow: row nowrap;
    display: flex;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    width: max-content;
  }



  .socialIcon {
    padding-right: 10px;
    padding-left: 10px;
    transition: transform .2s;
    margin-top: auto;
    margin-bottom: auto;
  }

  .socialIcon:hover {
    transform: scale(1.1);
  }


  /* width */
  ::-webkit-scrollbar {
    width: 10px;
    display: none;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: #3b3b3b;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #888;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: rgb(99, 31, 177);
  }

  .connect {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    font-family: 'Nunito', sans-serif;
    padding-top: 5px;
    margin-left: 10%;
  }

  .connect2 {
    padding-top: 5px;
  }

  .connect div {
    margin-left: 10px;
    margin-right: 10px;
    font-family: 'Nunito', sans-serif;
    color: white;
    padding-top: 15px;
    font-size: 16px;
  }

  .connect div:hover {
    margin-left: 10px;
    margin-right: 10px;
    font-family: 'Nunito', sans-serif;
    color: rgb(184, 184, 184);
    padding-top: 15px;
    cursor: pointer;

  }

  .nftblockWalletConnectedALL {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
  }

  .minting_count_button {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    height: max-content;
  }

  .nftminter2 {
    color: #00acee;
    font-size: 90px;
  }

  .center {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    justify-content: center;
  }

  .nftminter {
    background-color: #18181a;
    justify-content: flex-start;
  }

  .mintbuttondiv {
    text-align: center;
  }

  .nftblockWalletConnected {
    display: flex;
    margin-bottom: 10px;
  }

  .btnfos-0-2 {
    color: white;
    background-color: rgba(255, 255, 255, 0);
    border: white 2px solid;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    font-size: 20px;
    margin-left: 20px;
    margin-right: 20px;
    height: 50px;
    width: 50px;
    border: none;
    cursor: pointer;
    border-radius: 6px;
    background-color: rgba(255, 255, 255, 0);
    border: 2px solid white;
  }

  .btnfos-0-2:hover:active {
    background-color: #d3d3d3;
  }


  .btnfos-0-3 {
    font-size: 30px;
    margin-right: auto;
    margin-left: auto;
    justify-content: center;
    padding: 5px;
    border: none;
    justify-content: center;
    text-align: center;
    width: 250px;
    margin-bottom: 5%;
    color: white;
    background-color: rgba(255, 255, 255, 0);
    border: white 2px solid;
    margin-top: 5%;
    border-radius: 6px;
  }


  .btnfos-0-3:hover {
    color: #00acee;
    cursor: pointer;
  }

  .btnfos-0-3:hover:active {
    background-color: #d3d3d3;
  }

  .loadingContainer {
    text-align: center;
  }

  .successfully {
    text-align: center;
    color: rgb(124, 199, 74);
    font-size: 25px;
  }

  .headers {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: space-between;
    padding-top: 30px;
    margin-left: auto;
    margin-right: auto;
  }

  .headers2 {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
  }

  .introduction {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    margin-top: 2%;
    margin-left: auto;
    margin-right: auto;
    width: 90%;
  }

  .wallet2 {
    background-color: #9e4c3c00;
    border: #00acee 2px solid;
    color: white;
    padding: 5px;
    text-align: center;
    text-decoration: none;
    display: flex;
    font-size: 20px;
    padding-left: 25px;
    padding-right: 25px;
    transition: transform .2s;
    border-radius: 50px;
    font-weight: 500;
    margin-left: 10px;
  }

  .wallet2:hover {
    transform: scale(1.1);
  }

  .wallet3 {
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 30px;
    padding-left: 50px;
    padding-right: 50px;
    align-items: center;
    display: flex;
    transition: transform .5s;
    font-weight: 500;
    color: white;
    background: #00acee;
    border: none;
    border-radius: 100px;
    box-shadow: rgba(0, 0, 0, 0.1) 1px 2px 4px;
    margin-bottom: 10%;
    margin-left: auto;
    margin-right: auto;
    margin-top: 30px;
  }

  .wallet3:hover {
    animation: shake 0.82s cubic-bezier(.36, .07, .19, .97) both;
    transform: translate3d(0, 0, 0);
    perspective: 1000px;
  }



  .in2 {
    width: 90%;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
  }

  .totalSupply {
    text-align: center;
    font-size: 50px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    color: white;
    text-shadow: 4px 3px 0 #383d6e3a;
  }

  .logoPic {
    width: 100px;
    height: 100px;
    cursor: pointer;
    border-radius: 100%;
    border: 3px solid #00acee;

  }

  .logo {
    margin-top: 15px;
    margin-bottom: 15px;
  }

  .light {
    background: linear-gradient(180deg, rgba(0, 0, 0, 0.5794489670868348) 0%, rgba(0, 0, 0, 0.8371520483193278) 49%, rgba(0, 0, 0, 0.9239867822128851) 100%), url('./assets/bg.jpg');
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    min-height: 100vh;
    height: max-content;
    overflow: hidden;
  }

  .nftPic {
    width: 60%;
    box-shadow: -8px 8px rgb(63, 63, 63);
    border-radius: 20px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  }

}

@media screen and (min-width: 992px) and (max-width: 1199px) {
  .wallet2Mobile {
    display: none;
  }

  .wallet3Mobile {
    display: none;
  }

  .icons {
    display: flex;
  }

  .nftPicDiv {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: flex-end;
    display: flex;
  }

  .mintingAmount {
    font-size: 20px;
    padding: 10px;
  }

  .socialIcon img {
    width: 32px;
    height: 32px;
    cursor: pointer;
  }

  .price {
    text-align: center;
    font-size: 25px;
    color: #ffffff;
  }

  .allWrap {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    width: 100%;
    height: 100vh;
    min-height: 100vh;
    height: max-content;
  }

  .storyPicDiv {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
  }

  .intro {
    color: #ffffff;
    font-size: 30px;
    margin-bottom: 20px;
    font-family: 'Arimo', sans-serif;
    color: white;
    line-height: 1.2;
    font-weight: bold;
    text-shadow: #0a0a0a96 3px 5px 2px;

  }

  .intro2 {
    color: rgba(255, 255, 255, 0.918);
    font-size: 18px;
     font-size: 18px;
    text-align: left;
    width: 90%;
    text-shadow: 0 0 10px #ffffffe1;
  }

  .mintDiv {
    width: 65%;
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 5px solid #00acee;
    border-radius: 20px;
    padding: 10px;
  }

  .errorMessage {
    font-size: 18px;
    color: #FF4742;
    text-align: center;
  }

  .loadingText {
    font-size: 23px;
    text-align: center;
    color: rgb(255, 255, 255);
  }

  .loadTextSub {
    text-align: center;
    color: rgb(255, 255, 255);
    font-size: 13px;
    padding-top: 5px;
  }

  /* loading dots */

  .loadingText:after {
    content: '.';
    animation: dots 1s steps(5, end) infinite;
  }



  .right {
    flex-flow: row nowrap;
    display: flex;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    width: max-content;
  }


  .socialIcon {
    padding-right: 10px;
    padding-left: 10px;
    transition: transform .2s;
    margin-top: auto;
    margin-bottom: auto;
  }

  .socialIcon:hover {
    transform: scale(1.1);
  }


  /* width */
  ::-webkit-scrollbar {
    width: 10px;
    display: none;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: #3b3b3b;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #888;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: rgb(99, 31, 177);
  }

  .connect {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    font-family: 'Nunito', sans-serif;
    padding-top: 5px;
    margin-left: 10%;
  }

  .connect2 {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    padding-top: 5px;
  }

  .connect div {
    margin-left: 10px;
    margin-right: 10px;
    font-family: 'Nunito', sans-serif;
    color: white;
    padding-top: 15px;
    font-size: 16px;
  }

  .connect div:hover {
    margin-left: 10px;
    margin-right: 10px;
    font-family: 'Nunito', sans-serif;
    color: rgb(184, 184, 184);
    padding-top: 15px;
    cursor: pointer;

  }

  .nftblockWalletConnectedALL {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
  }

  .minting_count_button {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    height: max-content;
  }

  .nftminter2 {
    color: #00acee;
    font-size: 90px;
  }

  .center {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    justify-content: center;
  }

  .nftminter {
    background-color: #18181a;
    justify-content: flex-start;
  }

  .mintbuttondiv {
    text-align: center;
  }

  .nftblockWalletConnected {
    display: flex;
    margin-bottom: 10px;
  }

  .btnfos-0-2 {
    color: white;
    background-color: rgba(255, 255, 255, 0);
    border: white 2px solid;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    font-size: 20px;
    margin-left: 20px;
    margin-right: 20px;
    height: 50px;
    width: 50px;
    border: none;
    cursor: pointer;
    border-radius: 6px;
    background-color: rgba(255, 255, 255, 0);
    border: 2px solid white;
  }

  .btnfos-0-2:hover:active {
    background-color: #d3d3d3;
  }

  .btnfos-0-3 {
    font-size: 30px;
    margin-right: auto;
    margin-left: auto;
    justify-content: center;
    padding: 5px;
    border: none;
    justify-content: center;
    text-align: center;
    width: 250px;
    margin-bottom: 5%;
    color: white;
    background-color: rgba(255, 255, 255, 0);
    border: white 2px solid;
    margin-top: 5%;
    border-radius: 6px;
  }

  .btnfos-0-3:hover {
    color: #00acee;
    cursor: pointer;
  }

  .btnfos-0-3:hover:active {
    background-color: #d3d3d3;
  }

  .loadingContainer {
    text-align: center;
  }

  .successfully {
    text-align: center;
    color: rgb(124, 199, 74);
    font-size: 25px;
  }

  .headers {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: space-between;
    padding: 40px;
    margin-left: auto;
    margin-right: auto;
  }

  .headers2 {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    width: 85%;
    margin-left: auto;
    margin-right: auto;
  }

  .introduction {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    margin-top: 2%;
    margin-left: auto;
    margin-right: auto;
    width: 85%;
  }

  .wallet2 {
    background-color: #9e4c3c00;
    border: #00acee 2px solid;
    color: white;
    padding: 5px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 20px;
    padding-left: 25px;
    padding-right: 25px;
    transition: transform .2s;
    border-radius: 50px;
    font-weight: 500;
    margin-left: 10px;
  }

  .wallet2:hover {
    transform: scale(1.1);
  }

  .wallet3 {
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 25px;
    padding-left: 50px;
    padding-right: 50px;
    align-items: center;
    display: flex;
    transition: transform .5s;
    font-weight: 500;
    color: white;
    background: #00acee;
    border: none;
    border-radius: 100px;
    box-shadow: rgba(0, 0, 0, 0.1) 1px 2px 4px;
    margin-top: 30px;
  }

  .wallet3:hover {
    animation: shake 0.82s cubic-bezier(.36, .07, .19, .97) both;
    transform: translate3d(0, 0, 0);
    perspective: 1000px;
  }



  .in2 {
    width: 100%;
    text-align: left;
    margin-top: auto;
    margin-bottom: auto;
  }

  .totalSupply {
    text-align: center;
    font-size: 45px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    color: white;
    text-shadow: 4px 3px 0 #383d6e3a;
  }

  .logoPic {
    width: 100px;
    height: 100px;
    cursor: pointer;
    border-radius: 100%;
    border: 3px solid #00acee;

  }

  .logo {
    margin-top: auto;
    margin-bottom: auto;
  }

  .light {
    background: linear-gradient(62deg, rgba(0, 0, 0, 0.5934545693277311) 0%, rgba(0, 0, 0, 0.8175442051820728) 49%, rgba(0, 0, 0, 0.9239867822128851) 100%), url('./assets/bg.jpg');
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    min-height: 100vh;
    height: max-content;
    overflow: hidden;
  }

  .nftPic {
    width: 70%;
    box-shadow: -8px 8px rgb(63, 63, 63);
    border-radius: 20px;
  }

}

@media screen and (min-width: 1200px) and (max-width: 1919px) {
  .wallet2Mobile {
    display: none;
  }

  .wallet3Mobile {
    display: none;
  }


  .icons {
    display: flex;
  }

  .nftPicDiv {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: flex-end;
    display: flex;
  }

  .mintingAmount {
    font-size: 20px;
    padding: 10px;
  }

  .socialIcon img {
    width: 32px;
    height: 32px;
    cursor: pointer;
  }

  .socialIcon img2 {
    width: 70px;
    height: 70px;
    cursor: pointer;
    border-radius: 100px;
  }

  .price {
    text-align: center;
    font-size: 25px;
    color: #ffffff;
  }

  .allWrap {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    width: 100%;
    height: 100vh;
    min-height: 100vh;
    height: max-content;
  }

  .storyPicDiv {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
  }

  .intro {
    color: #ffffff;
    font-size: 45px;
    margin-bottom: 20px;
    font-family: 'Arimo', sans-serif;
    color: white;
    line-height: 1.2;
    font-weight: bold;
    text-shadow: #0a0a0a96 3px 5px 2px;
  }

  .intro2 {
    color: rgba(255, 255, 255, 0.918);
    font-size: 20px;
    text-align: justify;
    width: 90%;
    text-shadow: 0 0 10px #ffffffe1;
  }

  .mintDiv {
    width: 55%;
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 5px solid #00acee;
    border-radius: 20px;
    padding: 10px;
  }

  .errorMessage {
    font-size: 18px;
    color: #FF4742;
    text-align: center;
  }

  .loadingText {
    font-size: 23px;
    text-align: center;
    color: rgb(255, 255, 255);
  }

  .loadTextSub {
    text-align: center;
    color: rgb(255, 255, 255);
    font-size: 13px;
    padding-top: 5px;
  }

  .loadingText:after {
    content: '.';
    animation: dots 1s steps(5, end) infinite;
  }



  .right {
    flex-flow: row nowrap;
    display: flex;
    justify-content: space-evenly;
    width: max-content;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }


  .socialIcon {
    padding-right: 15px;
    transition: transform .2s;
    margin-top: auto;
    margin-bottom: auto;
  }

  .socialIcon:hover {
    transform: scale(1.1);
  }


  /* width */
  ::-webkit-scrollbar {
    width: 10px;
    display: none;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: #3b3b3b;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #888;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: rgb(99, 31, 177);
  }

  .connect {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    font-family: 'Nunito', sans-serif;
    padding-top: 5px;
    margin-left: 10%;

  }

  .connect2 {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    padding-top: 5px;
  }

  .connect div {
    margin-left: 10px;
    margin-right: 10px;
    font-family: 'Nunito', sans-serif;
    color: white;
    padding-top: 15px;
    font-size: 16px;
  }

  .connect div:hover {
    margin-left: 10px;
    margin-right: 10px;
    font-family: 'Nunito', sans-serif;
    color: rgb(184, 184, 184);
    padding-top: 15px;
    cursor: pointer;
  }

  .nftblockWalletConnectedALL {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
  }

  .minting_count_button {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    height: max-content;
  }

  .nftminter2 {
    color: #00acee;
    font-size: 90px;
  }

  .center {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    justify-content: center;
  }

  .nftminter {
    background-color: #18181a;
    justify-content: flex-start;
  }

  .mintbuttondiv {
    text-align: center;
  }

  .nftblockWalletConnected {
    display: flex;
    margin-bottom: 10px;
  }

  .btnfos-0-2 {
    color: white;
    background-color: rgba(255, 255, 255, 0);
    border: white 2px solid;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    font-size: 20px;
    margin-left: 20px;
    margin-right: 20px;
    width: 50px;
    height: 50px;
    border: none;
    cursor: pointer;
    justify-content: center;
    background-color: rgba(255, 255, 255, 0);
    border: 2px solid white;
    border-radius: 6px;
  }

  .btnfos-0-2:hover:active {
    background-color: #d3d3d3;
  }

  .btnfos-0-3 {
    font-size: 30px;
    margin-right: auto;
    margin-left: auto;
    justify-content: center;
    padding: 5px;
    border: none;
    justify-content: center;
    text-align: center;
    width: 250px;
    margin-bottom: 5%;
    color: white;
    background-color: rgba(255, 255, 255, 0);
    border: white 2px solid;
    margin-top: 5%;
    border-radius: 6px;
  }

  .btnfos-0-3:hover {
    color: #00acee;
    cursor: pointer;
  }

  .btnfos-0-3:hover:active {
    background-color: #d3d3d3;
  }

  .connectButton:hover {
    color: darkcyan;
    cursor: pointer;
  }

  .loadingContainer {
    text-align: center;
  }

  .successfully {
    text-align: center;
    color: rgb(124, 199, 74);
    font-size: 25px;
    margin-top: auto;
    margin-bottom: auto;
    display: flex;
    justify-content: center;
  }

  .headers {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: space-between;
    padding-top: 40px;
    padding-bottom: 40px;
    margin-left: auto;
    margin-right: auto;
  }

  .headers2 {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    width: 85%;
    margin-left: auto;
    margin-right: auto;
  }

  .introduction {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
     margin-left: auto;
    margin-right: auto;
    width: 85%;
  }

  .wallet2 {
    background-color: #9e4c3c00;
    border: #00acee 2px solid;
    color: white;
    padding: 5px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 20px;
    padding-left: 25px;
    padding-right: 25px;
    transition: transform .2s;
    border-radius: 50px;
    font-weight: 500;
    margin-left: 5px;
  }

  .wallet2:hover {
    transform: scale(1.1);
  }

  .wallet3 {
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 30px;
    padding-left: 50px;
    padding-right: 50px;
    align-items: center;
    display: flex;
    transition: transform .5s;
    font-weight: 500;
    color: white;
    background: #00acee;
    border: none;
    border-radius: 100px;
    box-shadow: rgba(0, 0, 0, 0.1) 1px 2px 4px;
    z-index: 100000000000;
    margin-top: 30px;
  }

  .wallet3:hover {
    animation: shake 0.82s cubic-bezier(.36, .07, .19, .97) both;
    transform: translate3d(0, 0, 0);
    perspective: 1000px;
  }



  .in2 {
    width: 90%;
    text-align: left;
    margin-top: auto;
    margin-bottom: auto;
  }

  .totalSupply {
    text-align: center;
    font-size: 55px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    color: white;
    text-shadow: 4px 3px 0 #383d6e3a;
  }

  .logoPic {
    width: 100px;
    height: 100px;
    cursor: pointer;
    border-radius: 100%;
    border: 3px solid #00acee;
  }

  .logo {
    margin-top: auto;
    margin-bottom: auto;
  }

  .light {
background:  linear-gradient(180deg, rgba(0,0,0,0.7475161939775911) 0%, rgba(0,0,0,0.3889727766106442) 50%, rgba(0,0,0,0.8567598914565826) 100%), url('./assets/bg.jpg');
/*background-color: #4a4949;?*/
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    min-height: 100vh;
    height: max-content;
    overflow: hidden;
  }

  .nftPic {
    width: 70%;
    border-radius: 20px;
    border: 7px solid #00acee;
  }
}

@media screen and (min-width: 1920px) {
  .wallet2Mobile {
    display: none;
  }

  .wallet3Mobile {
    display: none;
  }


  .icons {
    display: flex;
  }

  .nftPicDiv {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: flex-end;
    display: flex;
  }

  .socialIcon img {
    width: 45px;
    height: 45px;
    cursor: pointer;
  }

  .price {
    text-align: center;
    font-size: 35px;
    color: #ffffff;
  }

  .socialIcon {
    padding-right: 15px;
    transition: transform .2s;
    margin-top: auto;
    margin-bottom: auto;
  }

  .socialIcon:hover {
    transform: scale(1.1);
  }

  .allWrap {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    width: 100%;
    height: 100vh;
    min-height: 100vh;
    height: max-content;
  }

  .intro {
    color: #ffffff;
    font-size: 70px;
    margin-bottom: 10px;
    font-family: 'Arimo', sans-serif;
    color: white;
    line-height: 1.2;
    font-weight: bold;
    text-shadow: #0a0a0a96 3px 5px 2px;

  }

  .intro2 {
    color: rgba(255, 255, 255, 0.918);
    font-size: 30px;
    text-align: justify;
    width: 90%;
    text-shadow: 0 0 10px #ffffffe1;
  }

  .mintDiv {
    width: 60%;
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 5px solid #00acee;
    border-radius: 20px;
    padding: 20px;

  }

  .errorMessage {
    font-size: 30px;
    color: #FF4742;
    text-align: center;
  }

  .loadingText {
    font-size: 30px;
    text-align: center;
    color: rgb(255, 255, 255);
  }

  .loadTextSub {
    text-align: center;
    color: rgb(255, 255, 255);
    font-size: 13px;
    padding-top: 5px;
  }

  .loadingText:after {
    content: '.';
    animation: dots 1s steps(5, end) infinite;
  }



  .right {
    flex-flow: row nowrap;
    display: flex;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    width: max-content;
    margin-top: auto;
    margin-bottom: auto;
  }


  ::-webkit-scrollbar {
    width: 10px;
    display: none;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: #3b3b3b;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #888;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: rgb(99, 31, 177);
  }

  .connect {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    font-family: 'Nunito', sans-serif;
    padding-top: 5px;
    margin-left: 10%;
  }

  .connect2 {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    padding-top: 5px;
 
  }

  .connect div {
    margin-left: 10px;
    margin-right: 10px;
    font-family: 'Nunito', sans-serif;
    color: white;
    padding-top: 15px;
    font-size: 16px;
  }

  .connect div:hover {
    margin-left: 10px;
    margin-right: 10px;
    font-family: 'Nunito', sans-serif;
    color: rgb(184, 184, 184);
    padding-top: 15px;
    cursor: pointer;
  }

  .nftblockWalletConnectedALL {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
  }

  .minting_count_button {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    height: max-content;
  }

  .nftminter2 {
    color: #00acee;
    font-size: 140px;
  }

  .center {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    justify-content: center;
  }

  .nftminter {
    background-color: #18181a;
    justify-content: flex-start;
  }

  .mintbuttondiv {
    text-align: center;
  }

  .nftblockWalletConnected {
    display: flex;
    margin-bottom: 10px;
  }

  .btnfos-0-2 {
    color: white;
    background-color: rgba(255, 255, 255, 0);
    border: white 2px solid;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    font-size: 40px;
    margin-left: 40px;
    margin-right: 40px;
    height: 75px;
    width: 75px;
    border: none;
    cursor: pointer;
    border-radius: 6px;
    background-color: rgba(255, 255, 255, 0);
    border: 4px solid white;
  }

  .btnfos-0-2:hover:active {
    background-color: #d3d3d3;
  }

  .btnfos-0-3 {
    font-size: 45px;
    margin-right: auto;
    margin-left: auto;
    justify-content: center;
    padding: 5px;
    border: none;
    justify-content: center;
    text-align: center;
    width: 330px;
    margin-bottom: 5%;
    color: white;
    background-color: rgba(255, 255, 255, 0);
    border: white 4px solid;
    margin-top: 5%;
    border-radius: 6px;
  }

  .btnfos-0-3:hover {
    color: #00acee;
    cursor: pointer;
  }

  .btnfos-0-3:hover:active {
    background-color: #d3d3d3;
  }

  .loadingContainer {
    text-align: center;
  }

  .successfully {
    text-align: center;
    color: rgb(124, 199, 74);
    font-size: 25px;
  }

  .headers {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: space-between;
    padding-top: 40px;
    margin-left: auto;
    margin-right: auto;
  }

  .headers2 {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    width: 80%;
    margin-left: auto;
    margin-right: auto;
  }

  .introduction {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    margin-top: 7vh;
    margin-left: auto;
    margin-right: auto;
    width: 85%;
  }

  .wallet2 {
    background-color: #9e4c3c00;
    border: #00acee 4px solid;
    color: white;
    padding: 5px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 30px;
    padding-left: 35px;
    padding-right: 35px;

    transition: transform .2s;
    border-radius: 50px;
    font-weight: 500;
    margin-left: 10px;
  }

  .wallet2:hover {
    transform: scale(1.1);
  }

  .wallet3 {
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 40px;
    padding-left: 70px;
    padding-right: 70px;
    padding-top: 5px;
    padding-bottom: 5px;
    align-items: center;
    display: flex;
    transition: transform .5s;
    font-weight: 500;
    color: white;
    background: #00acee;
    border: none;
    border-radius: 100px;
    box-shadow: rgba(0, 0, 0, 0.1) 1px 2px 4px;
    margin-top: 40px;
  }

  .wallet3:hover {
    animation: shake 0.82s cubic-bezier(.36, .07, .19, .97) both;
    transform: translate3d(0, 0, 0);
    perspective: 1000px;
  }



  .in2 {
    width: 90%;
    text-align: left;
    margin-top: auto;
    margin-bottom: auto;
  }

  .totalSupply {
    text-align: center;
    font-size: 70px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    color: white;
    text-shadow: 4px 3px 0 #383d6e3a;
  }

  .logoPic {
    width: 160px;
    height: 160px;
    cursor: pointer;
    border-radius: 100%;
    border: 5px solid #00acee;

  }

  .logo {
    margin-top: auto;
    margin-bottom: auto;
  }

  .light2 {
    height: 200vh;
  }

  .light {
    background: linear-gradient(62deg, rgba(0, 0, 0, 0.5934545693277311) 0%, rgba(0, 0, 0, 0.8175442051820728) 49%, rgba(0, 0, 0, 0.9239867822128851) 100%), url('./assets/bg.jpg');
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    min-height: 100vh;
    height: max-content;
    overflow: hidden;
  }

  .nftPic {
    width: 100%;
    box-shadow: -12px 12px rgb(63, 63, 63);
    border-radius: 20px;
  }
}